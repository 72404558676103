<!-- @format -->

<template>
	<div>
		<div v-if="loading">Loading...</div>
		<div v-if="!loading">
			<div class="form-title">Time Interval Customer Payments</div>
			<div>
				<div>
					<b-button
						class="is-success margin-top-10"
						type="submit"
						@click="makeNewSearch"
						:disabled="loading"
						>Make New Search</b-button
					>
				</div>
				<b-field
					class="margin-top-20 text-red"
					style="font-size: larger; font-weight: 700"
					>Select beginning date or ending date and wait for results</b-field
				>
				<b-field class="margin-top-20" label="Select beginning date">
					<b-datepicker
						v-model="selectedBeginningDate"
						:show-week-number="showWeekNumber"
						:locale="locale"
						placeholder="Click to select..."
						icon="calendar-today"
						trap-focus
					>
					</b-datepicker>
				</b-field>
				<b-field label="Select ending date">
					<b-datepicker
						v-model="selectedEndingDate"
						:show-week-number="showWeekNumber"
						:locale="locale"
						placeholder="Click to select..."
						icon="calendar-today"
						trap-focus
					>
					</b-datepicker>
				</b-field>
				<div class="margin-top-30" v-if="!loading">
					<vue-good-table
						:columns="columns"
						:rows="rows"
						styleClass="vgt-table striped bordered"
						theme="black-rhino"
						:fixed-header="true"
						@on-cell-click="onCellClick"
						:search-options="{
							enabled: true,
						}"
						:sort-options="sortOptions"
						:pagination-options="{
							enabled: true,
							mode: 'records',
							perPage: 100,
							position: 'bottom',
							perPageDropdown: [5, 10, 15, 20, 100],
							dropdownAllowAll: false,
							setCurrentPage: 1,
							jumpFirstOrLast: true,
							firstLabel: 'First',
							lastLabel: 'Last',
							nextLabel: 'next',
							prevLabel: 'prev',
							rowsPerPageLabel: 'Rows per page',
							ofLabel: 'of',
							pageLabel: 'page', // for 'pages' mode
							allLabel: 'All',
						}"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field === 'invoiceNumber'">
								<p class="text-bold has-text-link text-align-right">
									<a href="url" style="text-decoration-line: underline">
										{{ props.row.invoiceNumber }}
									</a>
								</p>
							</span>
							<span v-else-if="props.column.field === 'payment'">
								<p class="text-bold text-align-right text-blue">
									{{ props.row.payment }}
								</p>
							</span>
							<span v-else-if="props.column.field === 'paymentExc'">
								<p class="text-bold text-align-right text-green">
									{{ props.row.paymentExc }}
								</p>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<download-csv :data="rows">
						<div class="margin-bottom-30 margin-top-30">
							<b-button class="is-success"> Download the List </b-button>
						</div>
					</download-csv>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import GetCustomerPaymentsTimeIntervalQuery from '@/_srcv2/views/pages/accounting/components/customer-payments/graphql/GetCustomerPaymentsTimeIntervalQuery.graphql'
import { reactive, ref, computed } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import Store from '@/store'
import dateformat from 'dateformat'
import numeral from 'numeral'
import useCustomerPaymentsState from '@/_srcv2/views/pages/accounting/components/customer-payments/scripts/useCustomerPaymentsState.js'

export default {
	name: 'ShowCustomerPayments',
	setup() {
		const showWeekNumber = false
		const locale = 'sv-SE'
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'paymentDate', type: 'asc' },
		}
		const {
			selectedCustomerId,
			showSearchBox,
			showPaymentsTable,
			selectedBeginningDate,
			selectedEndingDate,
		} = useCustomerPaymentsState()
		// todo Get Data from database
		const parameters = reactive({
			beginningDate: computed(() =>
				dateformat(selectedBeginningDate.value, 'yyyy-mm-dd'),
			),
			endingDate: computed(() =>
				dateformat(selectedEndingDate.value, 'yyyy-mm-dd'),
			),
		})
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { loading, onResult } = useQuery(
			GetCustomerPaymentsTimeIntervalQuery,
			() => ({
				our_company: Store.getters.getUserCurrentCompany,
				customer_id: selectedCustomerId.value,
				bd: parameters.beginningDate,
				ed: parameters.endingDate,
			}),
			options,
		)
		// todo ------------------------------------------------------------------
		const getPaymentType = (type) => {
			if (type.includes('bank')) {
				return 'bank'
			} else if (type.includes('cash')) {
				return 'kontant'
			} else if (type.includes('set off')) {
				return 'kontant'
			} else {
				return 'kvittering'
			}
		}
		console.log(getPaymentType('collect bank'))
		// todo ------------------------------------------------------------------
		const numberFromDataBaseToTable = (number, currency = 'kr.') => {
			return numeral(number / 100).format('0,0.00') + ' ' + currency
		}
		// todo ----------------------------------------------------------
		const mapRows = (arr) => {
			rows.value = arr.map((item) => {
				let customer = item.pyments_customers_rel
				return {
					customer:
						customer.customer_id +
						' - ' +
						customer.customer_title +
						' - ' +
						customer.customer_nickname,
					invoiceNumber: item.invoice_number,
					paymentDate: item.payment_date,
					payment: numberFromDataBaseToTable(item.payment_credit),
					paymentExc: numberFromDataBaseToTable(
						item.payment_credit_in_exchange,
						item.payment_currency,
					),
					exc: item.payment_currency,
					paymentType: getPaymentType(item.payment_type),
				}
			})
		}
		const rows = ref()
		onResult((result) => {
			console.log('onResult', result)
			mapRows(result.data.payments)
			console.log('rows', rows.value)
		})
		// todo Create the table columns
		const columns = reactive([
			{
				label: 'Customer',
				field: 'customer',
			},
			{
				label: 'Invoice Number',
				field: 'invoiceNumber',
				width: '115px',
			},
			{
				label: 'Payment date',
				field: 'paymentDate',
				width: '121px',
			},
			{
				label: 'Payment',
				field: 'payment',
				width: '165px',
				sortable: false,
			},
			{
				label: 'Payment Exc.',
				field: 'paymentExc',
				width: '165px',
				sortable: false,
			},
			{
				label: 'Payment Type',
				field: 'paymentType',
				width: '120px',
				sortable: false,
			},
		])
		// todo -------------------------------------------------------------
		const makeNewSearch = () => {
			selectedCustomerId.value = ''
			showSearchBox.value = true
			showPaymentsTable.value = false
		}
		// todo Create the table functions
		const onCellClick = (params) => {
			if (params.column.field === 'invoiceNumber') {
				console.log(params.row.invoiceNumber)
			}
		}
		return {
			selectedBeginningDate,
			selectedEndingDate,
			showWeekNumber,
			locale,
			columns,
			rows,
			loading,
			onCellClick,
			makeNewSearch,
			sortOptions,
		}
	},
}
</script>

<style lang="scss" scoped></style>
