/** @format */

import { reactive, toRefs } from '@vue/composition-api'
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

const state = reactive({
	selectedCustomerId: '',
	showSearchBox: true,
	showPaymentsTable: false,
	selectedBeginningDate: '',
	selectedEndingDate: '',
})

export default function useCustomerPaymentsState() {
	return {
		state,
		...toRefs(state),
	}
}
