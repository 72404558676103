import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const selectCompanyForm = reactive({
	columns: [
		{
			label: 'Select',
			field: 'select',
			width: '50px',
			sortable: false,
		},
		{
			label: 'Customer ID',
			field: 'customer_id',
			width: '135px',
			thClass: 'center-it',
		},
		{
			label: 'Customer Title',
			field: 'customer_title',
			thClass: 'center-it',
		},
		{
			label: 'Customer Nickname',
			field: 'customer_nickname',
			thClass: 'center-it',
		},
		{
			label: 'Org.Number',
			field: 'customer_org_num',
			width: '135px',
			thClass: 'center-it',
		},
	],
})

export default function storeSelectCompany() {
	return {
		...toRefs(selectCompanyForm),
	}
}
