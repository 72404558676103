<!-- @format -->

<template>
	<layout-wide>
		<div>
			<sub-navbar page="Customer" />
		</div>
		<div v-if="showSearchBox">
			<SearchCustomerForCustomerPayments />
		</div>
		<div v-if="showPaymentsTable">
			<ShowCustomerPayments />
		</div>
	</layout-wide>
</template>

<script>
import SearchCustomerForCustomerPayments from '@/_srcv2/views/pages/accounting/components/customer-payments/components/SearchCustomerForCustomerPayments.vue'
import ShowCustomerPayments from '@/_srcv2/views/pages/accounting/components/customer-payments/components/ShowCustomerPayments.vue'
import useCustomerPaymentsState from '@/_srcv2/views/pages/accounting/components/customer-payments/scripts/useCustomerPaymentsState.js'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'

export default {
	name: 'CustomerPayments',
	components: {
		SubNavbar,
		SearchCustomerForCustomerPayments,
		ShowCustomerPayments,
	},
	setup() {
		const { showSearchBox, showPaymentsTable } = useCustomerPaymentsState()
		return {
			showSearchBox,
			showPaymentsTable,
		}
	},
}
</script>

<style lang="scss" scoped></style>
