<!-- @format -->

<template>
	<div v-if="!loading">
		<section class="hero is-success margin-bottom-20">
			<div class="hero-body">
				<p class="title">Customer Payments Report</p>
				<p class="subtitle">
					Please Select a company to preview customer payments!
				</p>
			</div>
		</section>
		<ve-table
			:rows="rows"
			:columns="columns"
			:onCellClick="onCellClick"
			:onRowDoubleClick="onRowDoubleClick"
			:sort-options="sortOptions"
		>
		</ve-table>
	</div>
</template>

<script>
import Store from '@/store'
import { useQuery } from '@vue/apollo-composable'
import { onMounted, reactive, ref } from '@vue/composition-api'
import storeSelectCompany from '@/_srcv2/pages/transactions/add-transaction/scripts/useSelectCompanyForm.js'
import GetActiveCustomersForPaymentQuery from '@/_srcv2/pages/transactions/add-transaction/graphql/GetActiveCustomersForPaymentQuery.graphql'
import useCustomerPaymentsState from '@/_srcv2/views/pages/accounting/components/customer-payments/scripts/useCustomerPaymentsState.js'

export default {
	name: 'SearchCustomerForCustomerPayments',

	setup() {
		const {
			selectedCustomerId,
			showSearchBox,
			showPaymentsTable,
			selectedBeginningDate,
			selectedEndingDate,
		} = useCustomerPaymentsState()

		const { columns } = storeSelectCompany()
		const rows = ref([])
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'customer_title', type: 'asc' },
		}
		// todo ---------------------------------------------------------
		selectedEndingDate.value = new Date()
		selectedBeginningDate.value = new Date(
			selectedEndingDate.value.getFullYear(),
			selectedEndingDate.value.getMonth() - 3,
			1,
		)
		// todo ---------------------------------------------------------------
		// todo ------------------------------------------------------
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetchCustomers, loading } = useQuery(
			GetActiveCustomersForPaymentQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		const refetchData = () => {
			refetchCustomers().then((result) => {
				rows.value = result.data.customers
				console.log('rows.value', rows.value)
			})
		}
		onMounted(() => {
			refetchData()
		})
		// * -----------------------------------------------------------------
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				console.log('params.row', params.row)
				selectedCustomerId.value = params.row.customer_id
				showSearchBox.value = false
				showPaymentsTable.value = true
			}
		}
		const onRowDoubleClick = (params) => {
			console.log('params.row', params.row)
			selectedCustomerId.value = params.row.customer_id
			showSearchBox.value = false
			showPaymentsTable.value = true
		}
		return {
			columns,
			rows,
			onCellClick,
			onRowDoubleClick,
			sortOptions,
			loading,
		}
	},
}
</script>

<style scoped></style>
