var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',[_vm._v("Loading...")]):_vm._e(),(!_vm.loading)?_c('div',[_c('div',{staticClass:"form-title"},[_vm._v("Time Interval Customer Payments")]),_c('div',[_c('div',[_c('b-button',{staticClass:"is-success margin-top-10",attrs:{"type":"submit","disabled":_vm.loading},on:{"click":_vm.makeNewSearch}},[_vm._v("Make New Search")])],1),_c('b-field',{staticClass:"margin-top-20 text-red",staticStyle:{"font-size":"larger","font-weight":"700"}},[_vm._v("Select beginning date or ending date and wait for results")]),_c('b-field',{staticClass:"margin-top-20",attrs:{"label":"Select beginning date"}},[_c('b-datepicker',{attrs:{"show-week-number":_vm.showWeekNumber,"locale":_vm.locale,"placeholder":"Click to select...","icon":"calendar-today","trap-focus":""},model:{value:(_vm.selectedBeginningDate),callback:function ($$v) {_vm.selectedBeginningDate=$$v},expression:"selectedBeginningDate"}})],1),_c('b-field',{attrs:{"label":"Select ending date"}},[_c('b-datepicker',{attrs:{"show-week-number":_vm.showWeekNumber,"locale":_vm.locale,"placeholder":"Click to select...","icon":"calendar-today","trap-focus":""},model:{value:(_vm.selectedEndingDate),callback:function ($$v) {_vm.selectedEndingDate=$$v},expression:"selectedEndingDate"}})],1),(!_vm.loading)?_c('div',{staticClass:"margin-top-30"},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table striped bordered","theme":"black-rhino","fixed-header":true,"search-options":{
						enabled: true,
					},"sort-options":_vm.sortOptions,"pagination-options":{
						enabled: true,
						mode: 'records',
						perPage: 100,
						position: 'bottom',
						perPageDropdown: [5, 10, 15, 20, 100],
						dropdownAllowAll: false,
						setCurrentPage: 1,
						jumpFirstOrLast: true,
						firstLabel: 'First',
						lastLabel: 'Last',
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page', // for 'pages' mode
						allLabel: 'All',
					}},on:{"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'invoiceNumber')?_c('span',[_c('p',{staticClass:"text-bold has-text-link text-align-right"},[_c('a',{staticStyle:{"text-decoration-line":"underline"},attrs:{"href":"url"}},[_vm._v(" "+_vm._s(props.row.invoiceNumber)+" ")])])]):(props.column.field === 'payment')?_c('span',[_c('p',{staticClass:"text-bold text-align-right text-blue"},[_vm._v(" "+_vm._s(props.row.payment)+" ")])]):(props.column.field === 'paymentExc')?_c('span',[_c('p',{staticClass:"text-bold text-align-right text-green"},[_vm._v(" "+_vm._s(props.row.paymentExc)+" ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,1033877973)}),_c('download-csv',{attrs:{"data":_vm.rows}},[_c('div',{staticClass:"margin-bottom-30 margin-top-30"},[_c('b-button',{staticClass:"is-success"},[_vm._v(" Download the List ")])],1)])],1):_vm._e()],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }